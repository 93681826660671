<template>
  <div class="mx-auto">
    <ClaimEdit :claimId="this.id" />
  </div>
</template>

<script>
import ClaimEdit from "@/components/layout/claim/ClaimEdit";
export default {
  name: "Create",
  components: { ClaimEdit },
  props: ["id"]
};
</script>

<style scoped></style>
