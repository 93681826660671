<template>
  <div class="mx-auto">
    <v-row>
      <v-col cols="12">
        <v-card color="#F9F9F9" class="rounded-0 elevation-0 pa-4 search-blk">
          <v-row no-gutters>
            <v-col cols="12" class="mb-2">
              <v-text-field
                background-color="#fff"
                class="name-field"
                dense
                hide-details
                outlined
                placeholder="顧客名・物件名"
                v-model="formData.search"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="mb-2">
              <v-select
                class="form-text"
                outlined
                dense
                :items="claimCategories"
                hide-details="auto"
                placeholder="クレームカテゴリー"
                chips
                multiple
                clearable
                v-model="formData.categories"
              >
                <template v-slot:selection="{ attrs, item, select }">
                  <v-chip
                    class="mb-1"
                    small
                    close
                    v-bind="attrs"
                    @click="select"
                    @click:close="removeTag(item.value)"
                  >
                    <span>{{ item.text }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-btn block color="#ABCFFF" @click="search">
                {{ $t("search") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn height="28" class="btn-claim-register" color="#CFD3FE">
          <router-link :to="'/claim/create'">
            <v-icon left small>mdi-plus</v-icon><span>クレーム登録</span>
          </router-link>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="piece-sort-blk"
        >全{{ alluserclaims?.length }}件</v-col
      >
      <v-col cols="6" class="piece-sort-blk text-right">{{
        $t("sort_by")
      }}</v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-card elevation="0" class="rounded-0">
      <v-card-text>
        <v-row dense>
          <v-col
            v-for="(claim, i) in alluserclaims"
            :key="`${claim.id}-${claim.name}-${i}`"
            cols="12"
          >
            <v-row dense align="center">
              <v-col cols="7" class="claim-title">
                <router-link
                  :to="{ name: 'claim_detail', params: { id: claim.id } }"
                >
                  {{ claim.customer.name }}
                </router-link>
              </v-col>
              <v-col cols="5" class="text-right claim-date">
                {{ getDateFormat(claim.date) }}</v-col
              >
            </v-row>
            <v-row dense align="center">
              <v-col cols="auto" class="property-label">
                {{ $t("property_name") }}
              </v-col>
              <v-col cols="8" class="property-name ml-1" v-if="claim.site">
                {{ claim.site.site_id }} {{ claim.site.name }}
              </v-col>
              <v-col cols="2" class="text-right">
                <router-link :to="'/claim/edit/' + claim.id">
                  <v-btn fab x-small color="editColor" class="white--text">
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                </router-link>
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col cols="12">
                <v-chip
                  color="#8C5F5F"
                  text-color="white"
                  v-for="(category, index) in claim.categories"
                  :key="index"
                  label
                  small
                  class="mr-3 claim-tag"
                >
                  {{ category.name }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="auto" class="content-label">{{
                $t("content")
              }}</v-col>
              <v-col
                cols="10"
                class="content-txt ml-1"
                v-html="shortDes(claim.description, 60)"
              ></v-col>
            </v-row>
            <v-divider class="mt-4 mb-1"></v-divider>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ClaimList",
  data() {
    return {
      formData: {
        search: "",
        categories: []
      }
    };
  },
  computed: {
    ...mapGetters(["alluserclaims", "allClaimCategories"]),
    claimCategories() {
      return this.$store.getters.allClaimCategories.map(ClaimCategories => {
        return { value: ClaimCategories.id, text: ClaimCategories.name };
      });
    }
  },
  methods: {
    ...mapActions(["USER_CLAIM_ALL", "GET_CLAIM_CATEGORIES"]),
    getDateFormat(claim_date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(claim_date).toLocaleDateString("ja-JP", options);
    },
    shortDes(str, n) {
      return str?.length > n ? `${str.substr(0, n - 1)}…` : str;
    },
    async search() {
      await this.$store.dispatch("USER_CLAIM_ALL", this.formData);
    },
    removeTag(item) {
      this.formData.setTagName = this.formData.setTagName.filter(
        claimCategories => claimCategories !== item
      );
    }
  },
  created() {
    this.USER_CLAIM_ALL();
    this.GET_CLAIM_CATEGORIES();
  }
};
</script>

<style lang="scss" src="./ClaimList.scss" scoped></style>
