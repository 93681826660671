<template>
  <div class="mx-auto">
    <v-card elevation="0" class="rounded-0">
      <v-card-text>
        <div v-for="claims in claimById" :key="claims.id">
          <v-row dense align="center" class="mb-2">
            <v-col cols="6" class="claim-id">
              <span class="pr-2">Task Id:</span>
              <router-link :to="'/task/' + claims.task_id">{{ claims.task_id }}</router-link>
            </v-col>
            <v-col cols="6" class="claim-date text-right">{{ getDateFormat(claims.date) }}</v-col>
          </v-row>
          <v-row dense class="mb-2">
            <v-col cols="12">
              <h3 class="claim-info">{{ claims.customer.name }}<span class="pl-4" v-if="claims.site">{{
                  claims.site.site_id
              }} {{ claims.site.name }}</span></h3>
            </v-col>
            <v-col cols="12">
              <h4 class="claim-service">{{ claims.task.service.name }}</h4>
            </v-col>
          </v-row>
          <v-row dense class="mb-2">
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="auto" v-if="claims.leader">
                  <v-avatar class="member-avatar leader" size="40">
                    <img class="avatar"
                      :src="claims.leader.profile_image ? claims.leader.profile_image_url : `${root}images/userblank.png`" />
                  </v-avatar>
                  <div class="text-center member-name">
                    {{ claims.leader.last_name }}
                  </div>
                </v-col>
                <v-col cols="auto" v-for="(member, index) in getMemberDetail(claims)" :key="`member-${index}`">
                  <v-avatar class="member-avatar" size="40">
                    <img class="avatar"
                      :src="member.profile_image ? member.profile_image_url : `${root}images/userblank.png`" />
                  </v-avatar>
                  <div class="text-center member-name">
                    {{ member.last_name }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense class="mb-4">
            <v-col cols="12">
              <v-chip color="#8C5F5F" text-color="white"
                v-for="(claimCat, index) in getClainCat(claims.claim_category_id)" :key="index" label small
                class="mr-3 claim-tag">
                {{ claimCat }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <div class="px-6 py-3 d-block content-title">{{ $t("claim_des") }}</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="description-blk">
              <p>{{ claims.description }}</p>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <div class="px-6 py-3 d-block content-title">{{ $t("claim_resp") }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="description-blk">
              <p>{{ claims.response }}</p>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <div class="updated-blk text-center" v-if="claims.created_by">
                <span>{{ $t("created_by") }}: {{ claims.created_by.last_name }} {{ claims.created_by.first_name
                }}</span>
                <span class="cls-date">{{ $t("created_date") }} : {{ getDateFormat(claims.created_at) }}</span>
              </div>
              <div class="updated-blk text-center" v-if="claims.updated_by">
                <span v-if="claims.updated_by">{{ $t("updated_by") }}: {{ claims.updated_by.last_name }} {{
                    claims.updated_by.first_name
                }}</span>
                <span class="cls-date">{{ $t("updated_date") }} : {{ getDateFormat(claims.updated_at) }}</span>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ClaimDetail",
  props: ["claimId"],
  computed: {
    ...mapGetters(["claimById","allClaimCategories"])
  },
  methods: {
    ...mapActions(["USER_CLAIM_BY_ID","GET_CLAIM_CATEGORIES"]),
    getDateFormat(claim_date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(claim_date).toLocaleDateString("ja-JP", options);
    },
    getClainCat(claimCat){
      //let jsObject = JSON.parse(claimCat)
      let clainCatTxt = []
      this.allClaimCategories.map(function(value) {
        if(claimCat?.includes(value.id)){
          clainCatTxt.push(value.name);
        }
      });
      
      return clainCatTxt;
    },
    getMemberDetail(claims){

      let leaderId = 0;
      if(claims.leader){
        leaderId = claims.leader.id;
      }

      let task_members = []
      claims.task.task_members.map(function(taskMembers) {
          if(leaderId != taskMembers.id){
            task_members.push(taskMembers);
          }
      });
      
      return task_members;
    }
  },
  created() {
    //this.USER_CLAIM_BY_ID();
    this.$store.dispatch("USER_CLAIM_BY_ID", this.claimId || this.$route.params.id);
    this.GET_CLAIM_CATEGORIES();
  },
  data() {
    return {
      root: process.env.VUE_APP_ROOT_API,
    };
  },
};
</script>

<style lang="scss" src="./ClaimDetail.scss" scoped></style>
