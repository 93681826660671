<template>
  <ClaimList />
</template>

<script>
import ClaimList from "@/components/layout/claim/ClaimList";
export default {
  name: "Claims",
  components: { ClaimList }
};
</script>

<style scoped></style>
