<template>
  <div>
    <template v-if="loading">
      <v-skeleton-loader type="card, card, article, date-picker"></v-skeleton-loader>
    </template>
    <template v-else>
      <validation-observer ref="observer">
        <v-card elevation="0" class="rounded-0">
          <v-card-text>
            <form @submit.prevent="save">
              <div class="mb-8">
                <v-row dense class="mb-1">
                  <v-col cols="12" class="form-label">顧客名</v-col>
                  <v-col cols="12">
                    <validation-provider v-slot="{ errors }" name="お客様" rules="required">
                      <v-autocomplete class="form-field" :error-messages="errors" :error="errors.length !== 0"
                        hide-details="auto" placeholder="顧客名を入力してください" outlined :items="customer" item-text="text"
                        item-value="value" dense v-model="data.customerKey" :search-input.sync="search" hide-no-data
                        @keyup='submit()' @change="updateCustomer()">
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row dense class="mb-1">
                  <v-col cols="12" class="form-label">物件名</v-col>
                  <v-col cols="12">
                    <validation-provider v-slot="{ errors }" name="sites" rules="required">
                      <v-select class="form-field" hide-details placeholder="物件名を入力してください" outlined
                        :error-messages="errors" :error="errors.length !== 0" dense v-model="data.siteKey"
                        :items="sites" item-value="value" v-on="updateSite()"></v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row dense class="mb-1">
                  <v-col cols="12" class="form-label">サービスタイプ</v-col>
                  <v-col cols="12">
                    <validation-provider v-slot="{ errors }" name="services" rules="required">
                      <v-select class="form-field" dense outlined placeholder="サービスタイプを選択してください" hide-details
                        :error-messages="errors" :error="errors.length !== 0" v-model="data.service_id"
                        :items="services" item-value="value" v-on="updateService()"></v-select>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row dense class="mb-1">
                  <v-col cols="12" class="form-label">タスク</v-col>
                  <v-col cols="12">
                    <validation-provider v-slot="{ errors }" name="クレーム分類" rules="required">
                      <v-select class="form-field" dense outlined placeholder="task id / 日付 / サービス名 " hide-details
                        :error-messages="errors" :error="errors.length !== 0" v-model="data.task_id" :items="tasks"
                        item-value="value">
                      </v-select>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row dense class="mb-1">
                  <v-col cols="12" class="form-label">クレーム分類</v-col>
                  <v-col cols="12">
                    <validation-provider v-slot="{ errors }" name="tags" rules="required">
                      <v-select class="form-field" outlined :error-messages="errors" :error="errors.length !== 0" dense
                        :items="claimCategories" hide-details="auto" placeholder="クレーム分類を選択してください" chips multiple
                        clearable v-model="data.claim_category_id">
                        <template v-slot:selection="{ attrs, item, select }">
                          <v-chip class="mb-1" label small close color="#8C5F5F" text-color="white" v-bind="attrs"
                            @click="select" @click:close="removeTag(item.value)">

                            {{ item.text }}
                          </v-chip>
                        </template>
                      </v-select>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row dense class="mb-1">
                  <v-col cols="12" class="form-label">クレーム内容</v-col>
                  <v-col cols="12">
                    <validation-provider v-slot="{ errors }" name="des" rules="required">
                      <v-textarea class="form-field" dense outlined :error-messages="errors"
                        :error="errors.length !== 0" placeholder="クレームの内容を箇条書きにしてください" hide-details
                        v-model="data.description" @keyup='desCount()' :maxlength="descriptionMaxLength"></v-textarea>
                    </validation-provider>
                    <div class="text-right counter-txt">
                      {{ totalDescharacter ? totalDescharacter : data.description.length }} / {{ descriptionMaxLength }} 文字
                    </div>
                  </v-col>
                </v-row>

                <v-row dense class="mb-1">
                  <v-col cols="12" class="form-label">対応内容</v-col>
                  <v-col cols="12">
                    <validation-provider v-slot="{ errors }" name="res" rules="required">
                      <v-textarea class="form-field" dense outlined placeholder="どのように対応したかを記入してください" hide-details
                        :error-messages="errors" :error="errors.length !== 0" v-model="data.response"
                        @keyup='resCount()' :maxlength="responseMaxLength"></v-textarea>
                    </validation-provider>
                    <div class="text-right counter-txt">
                      {{ totalRescharacter ? totalRescharacter : data.response.length }} / {{ responseMaxLength }} 文字
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div class="claim-footer mb-8">
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-btn color="#AD4545" text @click="deleteTask" :loading="loading">
                      <v-icon left size="20"> mdi-delete </v-icon>削除する
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn @click="$router.push('/claim')" text>キャンセル</v-btn>
                  </v-col>
                  <v-col cols="4" class="text-right">
                    <v-btn @click="save" color="#4F55A7" class="white--text" :loading="submitLoading">更新する</v-btn>
                  </v-col>
                </v-row>
              </div>
            </form>
          </v-card-text>
        </v-card>
      </validation-observer>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: 'ClaimEdit',
  components: {},
  props: ["claimId"],
  data() {
    return {
      search: '#',
      preview: null,
      loading: true,
      smallLoading: true,
      data: {
        customerKey:null,
        siteKey:null,
        service_id:0,
        description:"",
        response: "",
        task_id: "",
        claim_category_id:[],
        customer_id: 0,
        created_by: "",
        updated_by: "",
        claim_id: ""
      },
      totalDescharacter:0,
      totalRescharacter:0,
      descriptionMaxLength: 1000,
      responseMaxLength: 1000,
      submitLoading: false
    }
  },
  computed: {
    ...mapGetters(["user"]),
    claim(){
      return this.$store.getters.claimById
    },
    customer() {
      if(this.$store.getters.allCustomers.length>0){
        return this.$store.getters.allCustomers.map((customers,key) => {
          return { value: key, text: customers.name };
        });
      }else{
        return [];
      }
    },
    sites() {
      if(this.data.customerKey != null && this.$store.getters.allCustomers.length>0){
        return this.$store.getters.allCustomers[this.data.customerKey].sites.map((site,key) => {
          return {value: key, text: site.name}
        })
      }else{
        return [];
      }
    },
    services() {
      if(this.data.customerKey != null && this.data.siteKey != null && this.$store.getters.allCustomers.length>0){
        if(this.$store.getters.allCustomers[this.data.customerKey].sites[this.data.siteKey]){
          return this.$store.getters.allCustomers[this.data.customerKey].sites[this.data.siteKey].projects.map((project) => {
            return {value: project.service_type_id, text: project.service_type}
          })
        }else{
          return [];
        }
      }else{
        return [];
      }
    },
    tasks() {
      let tasklist = [];
      if(this.data.customerKey != null && this.data.siteKey != null && this.$store.getters.allCustomers.length>0 && this.$store.getters.allCustomers[this.data.customerKey]?.sites[this.data.siteKey]){
        this.$store.getters.allCustomers[this.data.customerKey]?.sites[this.data.siteKey]?.tasks?.map((tasks) => {
            if(this.data.service_id == tasks.service_type_id  && this.data.customer_id == tasks.customer_id){
              tasklist.push({ value: tasks.id, text: tasks.id+" / "+tasks.date+" / "+ tasks.service.name });
            }
        });
      }
     return tasklist.sort((a, b) => b.value - a.value );
    },
    claimCategories() {
      return this.$store.getters.allClaimCategories.map((ClaimCategories) => {
        return { value: ClaimCategories.id, text: ClaimCategories.name };
      });
    }
  },
  created() {
    this.getAddtionalFromApi()

    this.$store.dispatch('USER_CLAIM_BY_ID', this.claimId).then(() => {
      this.loading = false
      this.getDataFromApi()
    })
  },
  methods: {
      ...mapMutations(['confirmationModal']),
      async getDataFromApi(data = {}) {
        let params = data
        params.search = this.claim[0].customer.name
        await this.$store.dispatch('ALL_CUSTOMER', params).then(() => {
          this.smallLoading = false
          this.patchEditFormValues()
        })
      },
      getCustomerApi(data = {}) {
        let params = data
        if(this.search == ""){
          params.search = "#"
        }else{
          params.search = this.search
        }
        this.$store.dispatch('ALL_CUSTOMER', params).then(() => {
          this.smallLoading = false
          this.patchEditFormValues()
        })
      },
      async getAddtionalFromApi() {
        // let params = data
        // await this.$store.dispatch('ALL_TASKS', params).then(() => {
        //     this.smallLoading = false
        // })

        await this.$store.dispatch('GET_CLAIM_CATEGORIES').then(() => {
          this.smallLoading = false
        })

      },
      patchEditFormValues() {
        if (this.claim && this.claim[0].id) {
          const customerIDKey = [];
          if(this.$store.getters.allCustomers.length>0){
            this.$store.getters.allCustomers.map((customers,key) => {
              if(customers.id == this.claim[0].customer_id){
              customerIDKey.push(key);
              }
            });
          }

          const siteIDKey = [];
          if(this.$store.getters.allCustomers.length>0 && customerIDKey.length>0){
            this.$store.getters.allCustomers[customerIDKey[0]].sites.map((site,key) => {
              if(site.id == this.claim[0].site_id){
                siteIDKey.push(key);
              }
            });
          }

          const serviceID = [];
          if(this.$store.getters.allCustomers.length>0  && customerIDKey.length>0){
            if(this.$store.getters.allCustomers[customerIDKey[0]].sites[siteIDKey[0]]){
              this.$store.getters.allCustomers[customerIDKey[0]].sites[siteIDKey[0]].projects.map((project) => {
                if(project.id == this.claim[0].project_id){
                  serviceID.push(project.service_type_id);
                }
              })
            }
          }

          this.data.description = this.claim[0].description
          this.data.response = this.claim[0].response
          this.data.task_id = this.claim[0].task_id
          this.data.claim_category_id = this.claim[0].categories.map(category => category.id)
          this.data.customerKey = customerIDKey[0]
          this.data.customer_id = this.claim[0].customer_id
          this.data.siteKey = siteIDKey[0]
          this.data.service_id = serviceID[0]
          this.data.created_by = this.user.id
          this.data.updated_by = this.user.id
        }
      },
      submit() {
        this.getCustomerApi()
      },
      updateCustomer(){
        this.data.customer_id = this.$store.getters.allCustomers[this.data.customerKey].id
        this.sites;
      },
      updateSite(){
        this.services;
      },
      updateService(){
        this.tasks
      },
      desCount: function(){
        this.totalDescharacter = this.data.description.length;
      },
      resCount: function(){
        this.totalRescharacter = this.data.response.length;
      },
      save() {
        this.$refs.observer.validate().then(success => {
          if (!success) {
            return
          }
          this.submitLoading = true
          let claim_id = this.claim[0].id
          this.data.customer_id = this.$store.getters.allCustomers[this.data.customerKey].id
          let data = {}

          data.claim_id = this.claim[0].id
          data.description = this.data.description
          data.response = this.data.response
          data.task_id = this.data.task_id
          data.claim_category_id = this.data.claim_category_id
          data.customer_id = this.data.customer_id
          data.created_by = this.user.id
          data.updated_by = this.user.id

          this.$store.dispatch('CUSTOMER_EDIT_CLAIM', { data, claim_id }).then(
            response => {
              if (response.data.status == 'success') {
                 this.$router.push({
                   path: '/claim'
                 })
              }
            },
            error => {
              throw error
            }
          ).finally(() => {this.submitLoading = false})
        })
      },
      removeTag(item) {
        this.data.claim_category_id = this.data.claim_category_id.filter((claimCategories) => claimCategories !== item);
      },
      deleteTask() {
        this.confirmationModal({
          text: 'このアクションを実施してよろしいですか',
          action: {
            process: {
              color: 'red',
              text: '実施する'
            },
            cancel: {
              color: 'secondary',
              text: 'キャンセル'
            }
          },
          func: this.processDelete
        })
      },
      processDelete() {
          let claim_id = this.claimId
          this.$store.dispatch('CUSTOMER_DELETE_CLAIM', {claim_id }).then(
            response => {
              if (response.data.status == 'success') {
                this.$router.push({
                  path: '/claim'
                })
              }
            },
            error => {
              throw error
            }
          )
      },
  },
}
</script>

<style lang="scss" src="./ClaimCreate.scss" scoped></style>
