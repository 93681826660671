<template>
  <validation-observer ref="observer">
    <v-card elevation="0" class="rounded-0">
      <v-card-text>
        <form @submit.prevent="save">
          <div class="mb-8">
            <v-row dense class="mb-1">
              <v-col cols="12" class="form-label">顧客名</v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="claimCustomer"
                  rules="required"
                >
                  <v-autocomplete
                    class="form-field"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    hide-details="auto"
                    placeholder="顧客名を入力してください"
                    outlined
                    :items="customers"
                    item-text="name"
                    item-value="id"
                    dense
                    v-model="data.customer"
                    :search-input.sync="search"
                    hide-no-data
                    :loading="smallLoading.customer"
                    @change="updateSiteList()"
                    @update:search-input="searchCustomer"
                    return-object
                  >
                  </v-autocomplete>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row dense class="mb-1">
              <v-col cols="12" class="form-label">物件名</v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="claimSite"
                  rules="required"
                >
                  <v-select
                    class="form-field"
                    hide-details="auto"
                    placeholder="物件名を入力してください"
                    outlined
                    dense
                    :items="sites"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    v-model="data.site"
                    item-text="name"
                    item-value="id"
                    return-object
                    :disabled="smallLoading.customer"
                    :loading="smallLoading.site"
                  ></v-select>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row dense class="mb-1">
              <v-col cols="12" class="form-label"> サービスタイプ</v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="claimService"
                  rules="required"
                >
                  <v-select
                    class="form-field"
                    dense
                    outlined
                    placeholder="サービスタイプを選択してください"
                    hide-details="auto"
                    v-model="data.service_id"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    :items="services"
                    item-value="id"
                    item-text="name"
                    :disabled="smallLoading.site"
                  ></v-select>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row dense class="mb-1">
              <v-col cols="12" class="form-label">タスク</v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="claimTask"
                  rules="required"
                >
                  <v-select
                    class="form-field"
                    dense
                    outlined
                    placeholder="task id / 日付 / サービス名 "
                    hide-details="auto"
                    :error-messages="errors"
                    v-model="data.task_id"
                    :items="tasks"
                    item-value="id"
                    item-text="text"
                    :disabled="data.service_id === null"
                  ></v-select>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row dense class="mb-1">
              <v-col cols="12" class="form-label">クレーム分類</v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="claimCategory"
                  rules="required"
                >
                  <v-select
                    class="form-field"
                    outlined
                    dense
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    :items="claimCategories"
                    hide-details="auto"
                    placeholder="クレーム分類を選択してください"
                    chips
                    multiple
                    clearable
                    v-model="data.claim_category_id"
                  >
                    <template v-slot:selection="{ attrs, item, select }">
                      <v-chip
                        class="mb-1"
                        label
                        small
                        close
                        color="#8C5F5F" 
                        text-color="white"
                        v-bind="attrs"
                        @click="select"
                        @click:close="removeTag(item.value)"
                      >
                        {{ item.text }}
                      </v-chip>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row dense class="mb-1">
              <v-col cols="12" class="form-label">クレーム内容</v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="claimDescription"
                  :rules="`required|max:${descriptionMaxLength}`"
                >
                  <v-textarea
                    class="form-field"
                    dense
                    outlined
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    placeholder="クレームの内容を箇条書きにしてください"
                    hide-details="auto"
                    v-model="data.description"
                    :maxlength="descriptionMaxLength"
                  ></v-textarea>
                </validation-provider>
                <div class="text-right counter-txt">
                  {{ `${data.description.length}/${descriptionMaxLength}` }}文字
                </div>
              </v-col>
            </v-row>

            <v-row dense class="mb-1">
              <v-col cols="12" class="form-label">対応内容</v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="claimResponse"
                  :rules="`required|max:${responseMaxLength}`"
                >
                  <v-textarea
                    class="form-field"
                    dense
                    outlined
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    placeholder="どのように対応したかを記入してください"
                    hide-details="auto"
                    v-model="data.response"
                    :maxlength="responseMaxLength"
                  ></v-textarea>
                </validation-provider>
                <div class="text-right counter-txt">
                  {{ `${data.response.length}/${responseMaxLength}` }}文字
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="claim-footer mb-8">
            <v-row>
              <v-col cols="6">
                <v-btn @click="$router.push('/claim')" text rounded block
                  >キャンセル</v-btn
                >
              </v-col>

              <v-col cols="6">
                <v-btn
                  @click="save"
                  color="#4F55A7"
                  class="white--text"
                  min-width="150"
                  block
                  :loading="smallLoading.submit"
                  >登録</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </form>
      </v-card-text>
    </v-card>
  </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "debounce";
export default {
  name: "ClaimCreate",
  components: {},
  data() {
    return {
      preview: null,
      search: "",
      initialLoad: false,
      smallLoading: {
        customer: false,
        site: false,
        submit: false
      },
      data: {
        customer: null,
        site: null,
        service_id: "",
        description: "",
        response: "",
        task_id: "",
        claim_category_id: [],
        customer_id: 0,
        created_by: "",
        updated_by: "",
        claim_id: ""
      },
      descriptionMaxLength: 1000,
      responseMaxLength: 1000
    };
  },
  computed: {
    ...mapGetters(["user", "allCustomers", "getSites"]),
    customers() {
      if (this.smallLoading.customer || this.initialLoad) return [];
      return this.allCustomers;
    },
    sites() {
      return this.getSites;
    },
    services() {
      if (!this.data.site?.projects) return [];
      return this.data.site?.projects?.filter(service => service.service_type_id !== 0).map(service => {
        return {
          id: service.service_type_id,
          name: service.service_type
        };
      });
    },
    tasks() {
      return this.data.site?.tasks
        ?.filter(task => task.service_type_id === this.data.service_id)
        .map(task => {
          return {
            id: task.id,
            text: `${task.id} / ${task.date} / ${task.service.name}`
          };
        });
    },
    claimCategories() {
      return this.$store.getters.allClaimCategories.map(category => {
        return { value: category.id, text: category.name };
      });
    }
  },
  methods: {
    searchCustomer: debounce(async function() {
      if (
        !this.search ||
        this.search?.toLowerCase() === this.data.customer?.name?.toLowerCase()
      )
        return;
      let params = {};
      params.search = this.search;
      params.without_site = true;
      this.smallLoading.customer = true;
      this.$store.dispatch("ALL_CUSTOMER", params).then(() => {
        this.smallLoading.customer = false;
        this.initialLoad = false;
      });
    }, 1000),
    updateSiteList() {
      if (!this.initialLoad) {
        this.data.site = null;
        this.data.service_id = null;
      }

      let params = {};
      params.id = this.data.customer.id;
      this.smallLoading.site = true;
      this.$store.dispatch("GET_SITE_BY_CUSTOMER", params).then(() => {
        this.smallLoading.site = false;
      });
    },
    save() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        this.smallLoading.submit = true;
        let params = {};
        params.description = this.data.description;
        params.response = this.data.response;
        params.task_id = this.data.task_id;
        params.claim_category_id = this.data.claim_category_id;
        params.customer_id = this.data.customer?.id;
        params.created_by = this.user.id;
        params.updated_by = this.user.id;

        this.$store
          .dispatch("CUSTOMER_CREATE_CLAIM", params)
          .then(
            response => {
              if (response.data.status == "success") {
                this.$router.push({
                  name: "ClaimList"
                });
              }
            },
            error => {
              throw error;
            }
          )
          .finally(() => (this.smallLoading.submit = false));
      });
    },
    removeTag(item) {
      this.data.claim_category_id = this.data.claim_category_id.filter(
        claimCategories => claimCategories !== item
      );
    }
  }
};
</script>

<style lang="scss" src="./ClaimCreate.scss" scoped></style>
