<template>
  <ClaimDetail :claimId="this.claimId" />
</template>

<script>
import ClaimDetail from "@/components/layout/claim/ClaimDetail";
export default {
  name: "Claims",
  components: { ClaimDetail },
  props: ["claimId"]
};
</script>

<style scoped></style>
