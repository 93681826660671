<template>
  <div class="mx-auto">
    <ClaimCreate />
  </div>
</template>

<script>
import ClaimCreate from "@/components/layout/claim/ClaimCreate";
export default {
  name: "Create",
  components: { ClaimCreate },
};
</script>

<style scoped></style>
